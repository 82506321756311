<template>
  <div class="wrapper">
    <div class="mint-content">
      <h1>
        Claim your Starter Kit!
        {{
          isConnected
            ? chestsRemaining + " Remaining"
            : "Connect to See Remaining Kits"
        }}
      </h1>
      <div class="columns">
        <div class="pricing">
          <h2>{{ pricePerNFT }} $DCAR Per Starter Kit</h2>
          <h4>
            You have {{ userMintsRemaining }} Mints Left and
            {{ currentDCAR }} DCAR
          </h4>
          <h4 v-if="this.account.length > 0">Connected with [{{ account }}]</h4>
          <template v-if="this.isConnected">
            <div v-if="this.isPaused && this.hasApproved" class="sale-paused">
              <h2>Sale has not started yet!</h2>
            </div>
            <div class="buttons-wrapper" v-if="this.hasApproved">
              <div class="button-wrapper">
                <img src="/assets/mages-kit.png" alt="Mage's Starter Kit" />
                <input type="number" v-model="magesKitQuantity" />
                <div
                  v-if="this.userMintsRemaining > 0"
                  @click="mintMages"
                  class="mint-button"
                >
                  Mint ({{ 300 * magesKitQuantity }} DCAR)
                </div>
              </div>
              <div class="button-wrapper">
                <img
                  src="/assets/warriors-kit.png"
                  alt="Warrior's Starter Kit"
                />
                <input type="number" v-model="warriorsKitQuantity" />
                <div
                  v-if="this.userMintsRemaining > 0"
                  @click="mintWarriors"
                  class="mint-button"
                >
                  Mint ({{ 300 * warriorsKitQuantity }} DCAR)
                </div>
              </div>
              <div class="button-wrapper">
                <img src="/assets/rangers-kit.png" alt="Ranger's Starter Kit" />
                <input type="number" v-model="rangersKitQuantity" />
                <div
                  v-if="this.userMintsRemaining > 0"
                  @click="mintRangers"
                  class="mint-button"
                >
                  Mint ({{ 300 * rangersKitQuantity }} DCAR)
                </div>
              </div>
              <div class="button-wrapper">
                <img
                  src="/assets/crafters-kit.png"
                  alt="Crafter's Starter Kit"
                />
                <input type="number" v-model="craftersKitQuantity" />
                <div
                  v-if="this.userMintsRemaining > 0"
                  @click="mintCrafters"
                  class="mint-button"
                >
                  Mint ({{ 300 * craftersKitQuantity }} DCAR)
                </div>
              </div>
            </div>
            <template v-else>
              <div @click="approve" class="mint-button">Approve DCAR</div>
            </template>
          </template>
          <template v-else>
            <div @click="connect" class="mint-button">Connect Wallet</div>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="view-heroes">View your Heroes</div> -->
  </div>
  <div class="section"></div>
  <div v-if="this.showLoading" class="loader">
    <p>Loading</p>
    <div @click="hideLoading" class="view-heroes">Close</div>
  </div>
  <div v-if="this.isSuccess" class="loader success">
    <p>Successfully Minted!</p>
    <div @click="hideSuccess" class="view-heroes">Okay - Back</div>
  </div>
</template>
<script>
import Web3 from "web3";
import TokenAbi from "../abi/dcau.json";
import MintAbi from "../abi/lootchests.json";
import Constants from "../consts/constants";
// import { ethers } from 'ethers';
import BigNumber from "bignumber.js";

export default {
  name: "Mint",
  data() {
    return {
      isConnected: false,
      hasApproved: false,
      isSuccess: false,
      hasFreeMint: false,
      isPaused: true,
      account: "",
      dcarContract: {},
      kitContract: {},
      showLoading: false,
      chestsRemaining: 0,
      userMintsRemaining: 0,
      currentDCAR: 0,
      web3: {},
      magesKitQuantity: 1,
      warriorsKitQuantity: 1,
      rangersKitQuantity: 1,
      craftersKitQuantity: 1,
    };
  },
  computed: {
    pricePerNFT() {
      return 300;
    },
  },
  methods: {
    async approve() {
      this.showLoading = true;

      const result = await this.dcarContract.methods
        .approve(Constants.starterKitContract, "1200000000000000000000")
        .send({ from: this.account });

      this.showLoading = false;

      if (result.status) {
        this.hasApproved = true;
      }
    },
    async connect() {
      const isWeb3 = await this.checkWeb3();

      if (isWeb3) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        this.account = accounts[0];
        this.isConnected = true;
      }

      await this.loadDcarContract();
      await this.loadMintContract();

      await this.checkApproved();

      await this.refreshData();
    },
    async loadDcarContract() {
      this.dcarContract = new this.web3.eth.Contract(
        TokenAbi,
        Constants.liveDcarContract
      );
    },
    async loadMintContract() {
      console.log("loadMintContract", Constants.starterKitContract);
      this.kitContract = new this.web3.eth.Contract(
        MintAbi,
        Constants.starterKitContract
      );
    },
    async checkMints() {
      console.log("kit contract", this.kitContract);

      const currentRound = await this.kitContract.methods
        .CURRENT_MINT_ROUND()
        .call();

      console.log("currentRound", currentRound);

      const availableMints = await this.kitContract.methods
        .AVAILABLE_MINTS_COUNT()
        .call();
      const currentMints = await this.kitContract.methods
        .CURRENT_MINT_COUNT()
        .call();

      this.chestsRemaining = availableMints - currentMints;

      this.isPaused = await this.kitContract.methods.IS_PAUSED().call();

      const mintsPerWallet = await this.kitContract.methods
        .MINT_PER_ADDRESS_LIMIT()
        .call();

      console.log("mintsPerWallet", mintsPerWallet);

      const userMintsTotal = await this.kitContract.methods
        .ADDRESS_MINT_COUNT_FOR_ROUND(currentRound, this.account)
        .call();

      this.userMintsRemaining = mintsPerWallet - userMintsTotal;
    },
    async checkApproved() {
      const result = await this.dcarContract.methods
        .allowance(this.account, Constants.starterKitContract)
        .call();
      if (result > 0) {
        this.hasApproved = true;
      }
    },
    async checkBalance() {
      const result = await this.dcarContract.methods
        .balanceOf(this.account)
        .call();

      this.currentDCAR = new BigNumber(result).div(10 ** 18).toFixed(2);
    },
    async refreshData() {
      await this.checkMints();
      await this.checkBalance();

      setTimeout(async () => {
        await this.refreshData();
      }, 1000);
    },
    async callProcessLogin(signature) {
      const response = await fetch(Constants.apiUrl + "processlogin", {
        method: "POST",
        body: JSON.stringify({ account: this.account, signature }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    },
    async callGetHeroes(sessionId) {
      const response = await fetch(Constants.apiUrl + "heroesforuser", {
        method: "POST",
        body: JSON.stringify({ account: this.account, sessionId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    },
    async callVerifyAPI(amount) {
      const response = await fetch(Constants.apiUrl + "verifyusermintinghero", {
        method: "POST",
        body: JSON.stringify({ account: this.account, amount }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    },
    hideSuccess() {
      this.isSuccess = false;
    },
    hideLoading() {
      this.showLoading = false;
    },
    async freeMint() {
      this.showLoading = true;

      const result = await this.kitContract.methods
        .freeMint()
        .send({ from: this.account });

      console.log(result);

      await this.checkMints();

      this.showLoading = false;
      this.isSuccess = true;
    },
    async mintMages() {
      this.showLoading = true;

      const result = await this.kitContract.methods
        .mint(3, this.magesKitQuantity)
        .send({ from: this.account });

      console.log(result);

      await this.checkMints();

      this.showLoading = false;
      this.isSuccess = true;
    },
    async mintWarriors() {
      this.showLoading = true;

      const result = await this.kitContract.methods
        .mint(1, this.warriorsKitQuantity)
        .send({ from: this.account });

      console.log(result);
      await this.checkMints();

      this.showLoading = false;
      this.isSuccess = true;
    },
    async mintRangers() {
      this.showLoading = true;

      const result = await this.kitContract.methods
        .mint(4, this.rangersKitQuantity)
        .send({ from: this.account });

      console.log(result);
      await this.checkMints();

      this.showLoading = false;
      this.isSuccess = true;
    },
    async mintCrafters() {
      this.showLoading = true;

      const result = await this.kitContract.methods
        .mint(2, this.craftersKitQuantity)
        .send({ from: this.account });

      console.log(result);
      await this.checkMints();

      this.showLoading = false;
      this.isSuccess = true;
    },
    async isAccountConnected() {
      return this.web3.isConnected();
    },
    async checkWeb3() {
      if (window.ethereum) {
        this.web3 = new Web3(window.ethereum);

        window.ethereum.on("accountsChanged", async () => {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          this.account = accounts.length > 0 ? accounts[0] : "";

          if (this.account != "") {
            this.isConnected = true;

            await this.loadDcarContract();
            await this.loadMintContract();

            await this.checkApproved();

            await this.refreshData();

            return true;
          }
        });
        return true;
      } else if (window.web3) {
        this.web3 = new Web3(window.web3.currentProvider);
        return true;
      }

      return false;
    },
  },
  async mounted() {
    const isWeb3 = await this.checkWeb3();

    if (isWeb3) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      this.account = accounts[0];
      this.isConnected = true;
    }

    await this.loadDcarContract();
    await this.loadMintContract();

    await this.checkApproved();

    await this.refreshData();

    // const connectedUser = await this.isAccountConnected()
    // console.log(connectedUser)
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

#app {
  overflow: hidden;
}

.wrapper {
  background: url("https://cdn.dragoncrypto.io/locationbackgrounds/ForgeBG.jpg")
    no-repeat center center;
  background-size: cover;
  padding-top: 238px;
  padding-bottom: 20px;

  @include respond-above(sm) {
    padding-top: 155px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.75);
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.75);
}

.view-heroes {
  background: #000;
  border: 1px solid #e5c356;
  color: #e5c356;
  font-size: 1.25rem;
  padding: 1rem 2rem;
  width: 250px;
  border-radius: 2px;
  margin: 0.75rem auto;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.2s linear;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s linear;
    cursor: pointer;
  }
}

.section {
  background: url("https://cdn.dragoncrypto.io/assets/divider.png") repeat-x top
    left;
  padding-bottom: 64px;
}

.mint-content {
  max-width: 96vw;
  margin: 1rem auto;

  video {
    max-height: 420px;
  }

  h1 {
    font-size: 3rem;
    color: #e5c356;
    margin-bottom: 0.45rem;
    text-shadow: 0 0 10px #000, 0 0 10px #000, 0 0 10px #000;
  }

  .mint-button {
    display: block;
    background: url("https://cdn.dragoncrypto.io/map/location-scroll.png")
      no-repeat top left;
    width: 311px;
    margin: 1rem auto;
    line-height: 82px;
    color: #000;
    transition: transform 0.2s linear;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s linear;
    }
  }
}

.columns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  div {
    margin: 1rem;
  }

  .pricing {
    width: 100%;
    background: rgba(0, 0, 0, 0.75);
    border: 1.5px solid #e5c356;
    border-radius: 3px;
    padding: 1.5rem;

    position: relative;

    .sale-paused {
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.75);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      backdrop-filter: blur(5px);
    }

    .buttons-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .button-wrapper {
        width: 22%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;

        img {
          margin-bottom: 20px;
          max-width: 90%;
        }

        input {
          max-width: 85%;
          padding: 12px 8px;
          text-align: center;
          font-size: 1.5rem;
          border: 1px solid #000;
          border-radius: 3px;
          background-color: #cb985d;
        }
      }
    }
  }
}
</style>
