<template>
  <div class="wrapper">
    <div class="mint-content">
      <h1>Summon your Heroes</h1>
      <div class="columns">
        <div class="pricing">
          <h2>$100 in DCAU per Hero ({{ pricePerNFT }} DCAU)</h2>
          <h3>No Heroes Waiting to be Summoned</h3>
          <div @click="login" class="mint-button">Login</div>
          <!-- <h4>
            You have {{ userMintsRemaining }} Mints Left and
            {{ currentDCAU }} DCAU
          </h4> -->
          <h4 v-if="this.account.length > 0">Connected with [{{ account }}]</h4>
          <template v-if="this.isConnected">
            <template v-if="this.hasApproved">
              <div
                v-if="this.freeMintsRemaining > 0"
                @click="freeMint"
                class="mint-button"
              >
                Mint Free
              </div>
              <!-- <div
                v-if="this.userMintsRemaining > 0 && this.heroesRemaining > 0"
                @click="mintOne"
                class="mint-button"
              >
                Mint One ({{ pricePerNFT }} DCAU)
              </div>
              <div
                v-if="this.userMintsRemaining > 1 && this.heroesRemaining > 1"
                @click="mintTwo"
                class="mint-button"
              >
                Mint Two ({{ pricePerNFT * 2 }} DCAU)
              </div> -->
            </template>
            <template v-else>
              <div @click="approve" class="mint-button">Approve DCAU</div>
            </template>
          </template>
          <template v-else>
            <div @click="connect" class="mint-button">Connect Wallet</div>
          </template>
        </div>
        <div class="image">
          <video autoplay loop muted>
            <source
              src="https://cdn.dragoncrypto.io/assets/characters.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
    <!-- <div class="view-heroes">View your Heroes</div> -->
  </div>
  <div class="section"></div>
  <div v-if="this.showLoading" class="loader">
    <p>Loading</p>
    <div @click="hideLoading" class="view-heroes">Close</div>
  </div>
  <div v-if="this.isSuccess" class="loader success">
    <p>Successfully Minted!</p>
    <div @click="hideSuccess" class="view-heroes">Okay - Back</div>
  </div>
</template>
<script>
import Web3 from "web3";
import Constants from "../consts/constants";
import DcauAbi from "../abi/dcau.json";
import MintAbi from "../abi/minting.json";
// import { ethers } from 'ethers';
import BigNumber from "bignumber.js";

export default {
  name: "Mint",
  data() {
    return {
      isConnected: false,
      hasApproved: false,
      isSuccess: false,
      hasFreeMint: false,
      account: "",
      dcauContract: {},
      heroContract: {},
      showLoading: false,
      heroesRemaining: 0,
      userMintsRemaining: 0,
      freeMintsRemaining: 0,
      dcauPricePerDollar: new BigNumber(0),
      currentDCAU: 0,
      web3: {},
    };
  },
  computed: {
    pricePerNFT() {
      return this.dcauPricePerDollar
        .multipliedBy(100)
        .div(10 ** 18)
        .toFixed(2);
    },
  },
  methods: {
    async approve() {
      this.showLoading = true;

      const result = await this.dcauContract.methods
        .approve(Constants.heroMintContract, "50000000000000000000")
        .send({ from: this.account });

      this.showLoading = false;

      if (result.status) {
        this.hasApproved = true;
      }
    },
    async login() {
      let signature = await this.web3.eth.personal.sign(
        "Sign this message to verify ownership of your wallet and log in to LOAD.",
        this.account,
        ""
      );
      console.log(signature);

      const result = await this.callProcessLogin(signature);

      console.log(result);

      const getHeroesResult = await this.callGetHeroes(result.sessionId);

      console.log(getHeroesResult);
    },
    connect() {
      // this.isConnected = true;
    },
    async loadDcauContract() {
      this.dcauContract = new this.web3.eth.Contract(
        DcauAbi,
        Constants.dcauContract
      );
    },
    async loadMintContract() {
      this.heroContract = new this.web3.eth.Contract(
        MintAbi,
        Constants.heroMintContract
      );
    },
    async checkMints() {
      this.heroesRemaining = await this.heroContract.methods
        .publicMintsRemaining()
        .call();
      this.userMintsRemaining = await this.heroContract.methods
        .addressMintsRemaining()
        .call({ from: this.account });
      this.freeMintsRemaining = await this.heroContract.methods
        .addressFreeMintsRemaining()
        .call({ from: this.account });
    },
    async checkPrice() {
      const result = await this.heroContract.methods
        .DCAUPricePerDollar()
        .call();

      this.dcauPricePerDollar = new BigNumber(result);
    },
    async checkApproved() {
      const result = await this.dcauContract.methods
        .allowance(this.account, Constants.heroMintContract)
        .call();
      if (result > 0) {
        this.hasApproved = true;
      }
    },
    async checkBalance() {
      const result = await this.dcauContract.methods
        .balanceOf(this.account)
        .call();

      this.currentDCAU = new BigNumber(result).div(10 ** 18).toFixed(2);
    },
    async refreshData() {
      await this.checkPrice();
      await this.checkMints();
      await this.checkBalance();

      setTimeout(async () => {
        await this.refreshData();
      }, 3000);
    },
    async callProcessLogin(signature) {
      const response = await fetch(Constants.apiUrl + "processlogin", {
        method: "POST",
        body: JSON.stringify({ account: this.account, signature }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    },
    async callGetHeroes(sessionId) {
      const response = await fetch(Constants.apiUrl + "heroesforuser", {
        method: "POST",
        body: JSON.stringify({ account: this.account, sessionId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    },
    async callVerifyAPI(amount) {
      const response = await fetch(Constants.apiUrl + "verifyusermintinghero", {
        method: "POST",
        body: JSON.stringify({ account: this.account, amount }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      return result;
    },
    hideSuccess() {
      this.isSuccess = false;
    },
    hideLoading() {
      this.showLoading = false;
    },
    async freeMint() {
      this.showLoading = true;

      const result = await this.heroContract.methods
        .freeMint()
        .send({ from: this.account });

      console.log(result);

      await this.checkMints();

      this.showLoading = false;
      this.isSuccess = true;
    },
    async mintOne() {
      this.showLoading = true;

      const data = await this.callVerifyAPI(1);

      const result = await this.heroContract.methods
        .mint(data.signature, data.nonce, 1)
        .send({ from: this.account });

      console.log(result);

      await this.checkMints();

      this.showLoading = false;
      this.isSuccess = true;
    },
    async mintTwo() {
      this.showLoading = true;

      const data = await this.callVerifyAPI(2);

      const result = await this.heroContract.methods
        .mint(data.signature, data.nonce, 2)
        .send({ from: this.account });

      console.log(result);

      await this.checkMints();

      this.showLoading = false;
      this.isSuccess = true;
    },
    async isAccountConnected() {
      return this.web3.isConnected();
    },
    async checkWeb3() {
      if (window.ethereum) {
        this.web3 = new Web3(window.ethereum);

        window.ethereum.on("accountsChanged", async () => {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          this.account = accounts.length > 0 ? accounts[0] : "";
        });

        return true;
      } else if (window.web3) {
        this.web3 = new Web3(window.web3.currentProvider);
        return true;
      }

      return false;
    },
  },
  async mounted() {
    const isWeb3 = await this.checkWeb3();

    if (isWeb3) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      this.account = accounts[0];
      this.isConnected = true;
    }

    await this.loadDcauContract();
    await this.loadMintContract();

    await this.checkApproved();

    await this.refreshData();

    // const connectedUser = await this.isAccountConnected()
    // console.log(connectedUser)
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/globals.scss";

#app {
  overflow: hidden;
}

.wrapper {
  background: url("https://cdn.dragoncrypto.io/assets/plainsbg.jpg") no-repeat
    top center;
  padding-top: 238px;
  padding-bottom: 20px;

  @include respond-above(sm) {
    padding-top: 155px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.75);
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  font-size: 1.5rem;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.75);
}

.view-heroes {
  background: #000;
  border: 1px solid #e5c356;
  color: #e5c356;
  font-size: 1.25rem;
  padding: 1rem 2rem;
  width: 250px;
  border-radius: 2px;
  margin: 0.75rem auto;
  text-transform: uppercase;
  cursor: pointer;
  transition: transform 0.2s linear;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s linear;
    cursor: pointer;
  }
}

.section {
  background: url("https://cdn.dragoncrypto.io/assets/divider.png") no-repeat
    top left;
  padding-bottom: 64px;
}

.mint-content {
  max-width: 96vw;
  margin: 1rem auto;

  video {
    max-height: 420px;
  }

  h1 {
    font-weight: bold;
    color: #000;
    text-shadow: 0 0 5px #e5c356;
    margin-bottom: 0.45rem;
  }

  .mint-button {
    display: block;
    background: url("https://cdn.dragoncrypto.io/map/location-scroll.png")
      no-repeat top left;
    width: 311px;
    margin: 1rem auto;
    line-height: 82px;
    color: #000;
    transition: transform 0.2s linear;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s linear;
    }
  }
}

.columns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  div {
    margin: 1rem;
  }

  .pricing {
    background: rgba(0, 0, 0, 0.5);
    border: 1.5px solid #e5c356;
    border-radius: 3px;
    padding: 1.5rem;
  }
}
</style>
